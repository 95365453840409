<template>
  <div class="subtitle-1">
    <span class="subheader">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="sass" scoped>
div
  padding: 12px 0

.subheader
  display: block
  color: rgba(0, 0, 0, 0.84)
  font-weight: 500
</style>