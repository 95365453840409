<template>
  <v-card>
    <v-card-title class="primary white--text">{{ title }}</v-card-title>
    <v-card-text>
      <form-section class="pt-3" title="Datos del condensador"/>
      <v-form ref="form" v-model="validation.valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field :readonly="readOnly"
                          :rules="validation.rules.trademark"
                          :value="trademark"
                          label="Marca *"
                          outlined
                          placeholder="Ingrese la marca"
                          @input="value => $emit('update:trademark', value)"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field :readonly="readOnly"
                          :rules="validation.rules.model"
                          :value="model"
                          label="Modelo *"
                          outlined
                          placeholder="Ingrese el modelo"
                          @input="value => $emit('update:model', value)"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field :readonly="readOnly"
                          :rules="validation.rules.serie"
                          :value="serie"
                          label="Serie *"
                          outlined
                          placeholder="Ingrese la serie"/>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field :readonly="readOnly"
                          :rules="validation.rules.voltage"
                          :value="voltage"
                          label="Voltaje *"
                          min="0"
                          outlined
                          placeholder="Ingrese un valor"
                          step="0.50"
                          type="number"
                          @input="value => $emit('update:voltage', value)"/>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field :readonly="readOnly"
                          :rules="validation.rules.amperage"
                          :value="amperage"
                          label="Amperaje *"
                          min="0"
                          outlined
                          placeholder="Ingrese un valor"
                          step="0.50"
                          type="number"
                          @input="value => $emit('update:amperage', value)"/>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field :readonly="readOnly"
                          :value="highPressure"
                          label="Presión Alta"
                          min="0"
                          outlined
                          placeholder="Ingrese un valor"
                          step="0.50"
                          type="number"
                          @input="value => $emit('update:highPressure', value)"/>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field :readonly="readOnly"
                          :value="lowPressure"
                          label="Presión Baja"
                          min="0"
                          outlined
                          placeholder="Ingrese un valor"
                          step="0.50"
                          type="number"
                          @input="value => $emit('update:lowPressure', value)"/>
          </v-col>
          <v-col cols="12">
            <v-textarea :readonly="readOnly"
                        :value="details"
                        label="Detalles"
                        outlined
                        placeholder="Ingrese alguna descripción relevante del condensador"
                        @input="value => $emit('update:details', value)"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="!readOnly">
      <v-btn outlined @click="onCancel">Cancelar</v-btn>
      <v-spacer/>
      <v-btn :disabled="!validation.valid" color="primary" @click="onSave">Guardar</v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-spacer/>
      <v-btn outlined @click="onCancel">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormSection from '@/components/widgets/Section'

export default {
  name: "CondenserForm",
  components: { FormSection },
  props: [
    'code',
    'trademark',
    'model',
    'serie',
    'voltage',
    'amperage',
    'highPressure',
    'lowPressure',
    'details',
    'readOnly'
  ],
  data: () => ({
    validation: {
      valid: false,
      rules: {
        trademark: [v => !!v || "La marca es requerida"],
        model: [v => !!v || "El modelo es requerido"],
        serie: [v => !!v || "Ingrese la serie"],
        voltage: [v => !!v || "Valor requerido"],
        amperage: [v => !!v || "Valor requerido"],
      }
    },
  }),
  computed: {
    title() {
      if (this.readOnly)
        return `Condensador ${this.code}`

      if (!this.readOnly && this.code)
        return `Editar condensador ${this.code}`

      return 'Nuevo condensador'
    },
  },
  methods: {
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit('save',
          {
            code: this.code,
            trademark: this.trademark,
            model: this.model,
            serie: this.serie,
            voltage: this.voltage,
            amperage: this.amperage,
            highPressure: this.highPressure,
            lowPressure: this.lowPressure,
            details: this.details,
          })
      }
    },
    onCancel() {
      this.$refs.form.resetValidation()
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>